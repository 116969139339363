.App {
  font-family: 'Akira';
  /* width: 1600px; */
  width: 100%;
  margin: auto;
  position: relative;
  background-color: #FBFBFB;
  overflow: hidden;
}
.App .contact{
  
  background-color: #0C0F11;
}
.navbar-container{
  position: fixed;
  top: 3%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  /* margin: 0 150px; */
  background-color: #0C0F11;
  border-radius: 100px;
  border: 3px solid #FBFBFB;
  /* width: 1300px; */
  margin: 0 auto;
  width: 85%;
  z-index: 2;
}
.navbar-container .initials-container img{
  width: 50px;
  padding-left: 30px;
  
}
.navbar-container .mobile-icon{
  display: none;
}
.navbar-container .tab-container .tabs{
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  margin: 0;
}
.navbar-container .tab-container .tabs .tab{
  padding: 10px 12px;
  color: #FBFBFB;
}
.navbar-container .tab-container .tabs .tab::after{
  display:block;
  content: '';
  height: 3px;
  margin-top: 1px;
  background: linear-gradient(124deg, #da1d00, #d31b1b, #cfa51a, #c2c519, #18c735, #19bdc5, #2519c9, #c300d4, #dd00f3);
  background-clip:border-box;
  background-size: 1000% 1000%;
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.navbar-container .tab-container .tabs .tab:hover{
  cursor: pointer;
  background: linear-gradient(124deg, #da1d00, #d31b1b, #cfa51a, #c2c519, #18c735, #19bdc5, #2519c9, #c300d4, #dd00f3);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1800% 1800%;
  animation: rainbow 10s ease infinite;
  
}
.navbar-container .tab-container .tabs .tab:hover::after{
  transform: scaleX(1);
  animation: rainbow 10s ease infinite;
}
.navbar-container .tab-container .tabs .tab:nth-child(even):hover{
  background: linear-gradient(124deg, #dd00f3, #c300d4,#2519c9, #19bdc5, #18c735, #c2c519,#cfa51a, #d31b1b, #da1d00 );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1800% 1800%;
  animation: rainbow 10s ease infinite;
}
.navbar-container .tab-container .tabs .tab:nth-child(even):hover::after{
  background: linear-gradient(124deg, #dd00f3, #c300d4,#2519c9, #19bdc5, #18c735, #c2c519,#cfa51a, #d31b1b, #da1d00 );;  background-clip:border-box;
  background-size: 700% 700%;
  animation: rainbow 10s ease infinite;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media(max-width: 1000px){
  .navbar-container .tab-container .tabs{
    font-size: 12px;
  }
  .navbar-container .initials-container img{
    width: 75px;
    padding: 0;
  }
}
@media(max-width: 850px){

  .home{
    background-color: #0C0F11;
    
  }


  .navbar-container{
    justify-content: space-between;
    width: 35%;
    flex-wrap: wrap;
    transition: all .4s ease;
    border-radius: 12px;
    top: 2%;
  }
  .navbar-container.open{
    width: 90%;

  }
  .navbar-container .initials-container img{
    padding-left: 17.5px;
    width: 30px;
  }
  .navbar-container .initials-container, .mobile-icon{
    /* padding: 0 5px;  */
  }
  .navbar-container .mobile-icon{
    display: block;
    position: relative;
    width: 20px;
    height: 30px;
    cursor: pointer;
    pointer-events: auto;
    transition: all .3s;
    padding-right: 40px;
  }
  .navbar-container .mobile-icon div:nth-child(1){
    top: -10px; 
  }
  .navbar-container.open .mobile-icon div:nth-child(1){
    top: 0;
    transform: rotate(45deg);
  }
  .navbar-container.open .mobile-icon div:nth-child(2){
    opacity: 0;
  }
  .navbar-container .mobile-icon div{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 17.5px;
    height: 2px;
    background-color: #fff;
    transform: translateZ(0);
    transition: all .3s;
    
  }
  .navbar-container .mobile-icon div:nth-child(3){
    top: 10px;
  }
  .navbar-container.open .mobile-icon div:nth-child(3){
    top: 0;
    transform: rotate(-45deg);
  }
  .navbar-container .tab-container{
    max-height: 0;
    transition: all .2s;
    overflow: hidden;
    transition-delay: .4s;
  }
  .navbar-container .tab-container.open{
    display: block;
    flex: 100%;
    max-height: 30vh;
    transition: all .4s;
    transition-delay: .4s;
  }
  .navbar-container .tab-container .tabs{
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 5px 15px;
    opacity: 0;
    transition: all 0.1s ease;

  }
  .navbar-container .tab-container.open .tabs{
    opacity: 1;
    transition: all 0.4s ease;
    transition-delay: .4s;
  }
  .navbar-container .tab-container .tabs .tab{
    text-align: left;
    font-size: 10px;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
