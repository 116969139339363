.projects-container{
    padding: 100px 150px 0;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    background-color: #FBFBFB;
    border-top: 5px solid #0C0F11;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: -120px;
    overflow: hidden;
}

.projects-container .header.animate{
    transform: translateX(0px);
    opacity: 1;
    transition: all 1s ease;
}
.projects-container .header{
    flex: 100%;
    font-size: 46px;
    text-align: left;
    margin: 50px 0;
    transform: translateX(-100px);
    opacity: 0;
    transition: all 1s ease;
    background: linear-gradient(124deg,#18c735, #cfa51a, #c2c519,#da1d00, #d31b1b, #19bdc5, #2519c9, #c300d4, #dd00f3);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 1800% 1800%;
    animation: rainbow 14s ease infinite;
}
.projects-container .projects{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 300px);
    width: 100%;
    padding-bottom: 60px;

}
.projects-container .projects .border-animate{
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 50px);
    top: -20px;
    left: -20px;
    pointer-events: none;
    background-image: radial-gradient(rgb(26, 26, 26) 30%, transparent 30%), radial-gradient(rgb(26, 26, 26) 30%, transparent 30%);
    background-position: 0px 0px, 20px 20px;
    background-size: 8px 8px;
    transition: inherit;
    animation: 4s linear 0s infinite normal none running borderAni;
}
.projects-container .projects .project-container{
    position: relative;
    overflow: hidden;
    border: 5px solid #0C0F11;
    width: 100%;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: unset;

}
.projects-container .projects .project-container.grid-col-3{
    /* grid-column-start: 1;
    grid-column-end: span 2; */
   
}
.projects-container .projects .project-container.grid-row-2{
    grid-row: span 2;
}
.projects-container .projects .project-container.grid-col-2{
    grid-column: span 2;
}
.projects-container .projects .project-container .project1, .projects .project-container .project2, .projects .project-container .project3, .projects .project-container .project4, .projects .project-container .project5, .projects .project-container .project6, .projects .project-container .project7, .projects .project-container .project8{
    width: 95%;
    margin: auto;
    height: 90%;
    position: relative;
}
.projects-container .projects.animate .project-container.one{
    transform: translateX(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.one{
    transform: translateX(-150%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project1{
    background-image: url('../images/projects/jordon.jpeg');
    background-size: cover;
    background-position: 75% 75%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    
}
.projects-container .projects .project-container .project1:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.two{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.two{
    transform: translateY(-200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project2{
    background-image: url('../images/projects/8lab.jpg');
    background-size: cover;
    background-position: 75% 50%;
    -webkit-filter: invert(100%); 
    filter: invert(100%);
    border: 3px solid #0C0F11;
}
.projects-container .projects .project-container .project2:hover{
    -webkit-filter: invert(0%); 
    filter: invert(0%);
}
.projects-container .projects .project-container .project2 img{
    -webkit-filter: invert(100%); 
    filter: invert(100%);
}
.projects-container .projects .project-container .project2 span{
    -webkit-filter: invert(100%); 
    filter: invert(100%);
}
.projects-container .projects .project-container .project2:hover img{
    -webkit-filter: invert(0%); 
    filter: invert(0%);
}
.projects-container .projects .project-container .project2:hover span{
    -webkit-filter: invert(0%); 
    filter: invert(0%);
}
.projects-container .projects.animate .project-container.three{
    transform: translateX(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.three{
    transform: translateX(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project3{
    background-image: url('../images/projects/scalimg.jpg');
    background-size: cover;
    background-position: 75% 50%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 95%;
    width: 95%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project3:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.four{
    transform: translateX(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.four{
    transform: translateX(-200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project4{
    background-image: url('../images/projects/mahone.jpg');
    background-size: cover;
    background-position: 75% 15%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 92%;
    width: 97.5%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project4:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.five{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.five{
    transform: translateY(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project5{
    background-image: url('../images/projects/mid22.jpg');
    background-size: cover;
    background-position: 50%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 95%;
    width: 95%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project5:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.six{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.six{
    transform: translateY(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project6{
    background-image: url('../images/projects/g4boyz.jpg');
    background-color: blue;
    background-size: cover;
    background-position: 75% 10%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project6:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.seven{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.seven{
    transform: translateY(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project7{
    background-image: url('../images/projects/deAnima.jpg');
    background-size: cover;
    background-position: 75% 15%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project7:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.eight{
    transform: translateX(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.eight{
    transform: translateX(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project8{
    background-image: url('../images/projects/demmo.jpg');
    background-size: cover;
    background-position: 80% 20%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 92%;
    width: 97.5%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project8:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.nine{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.nine{
    transform: translateX(-150%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project9{
    background-image: url('../images/projects/nutrifaye.jpg');
    background-size: cover;
    background-position: 0% 0%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project9:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.ten{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.ten{
    transform: translateY(150%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project10{
    background-image: url('../images/projects/lmww.jpg');
    background-size: cover;
    background-position: 75% 45%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 92%;
    width: 97.5%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .project10:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects.animate .project-container.eleven{
    transform: translateY(0%);
    opacity: 1;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container.eleven{
    transform: translateY(200%);
    opacity: 0;
    transition: all 1.25s ease;
}
.projects-container .projects .project-container .project11{
    background-image: url('../images/projects/southcloud.jpg');
    background-size: cover;
    background-position: 50%;
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    border: 3px solid #0C0F11;
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
}
.projects-container .projects .project-container .david{
    background-image: url('../images/projects/morrel.jpg');
    
}
.projects-container .projects .project-container .kp{
    background-image: url('../images/projects/kplog.png');
}
.projects-container .projects .project-container .kizzy{
    background-image: url('../images/projects/kizzy.jpg');
}
.projects-container .projects .project-container .project11:hover{
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}
.projects-container .projects .project-container .speech-bubble.small img{
    width: 125px;
    position: absolute;
    left: 0%;
}
.projects-container .projects .project-container .speech-bubble.small span{
    position: absolute;
    top: 15%;
    left: 6%;
}
.projects-container .projects .project-container .speech-bubble.medium img{
    width: 180px;
    position: absolute;
    left: 0%;
}
.projects-container .projects .project-container .speech-bubble.medium span{
    position: absolute;
    top: 25%;
    left: 8%;
    
}
.projects-container .projects .project-container .speech-bubble.medium2 img{
    width: 250px;
    position: absolute;
    top: 20%;
    right: 0%;
}
.projects-container .projects .project-container .speech-bubble.medium2 span{
    position: absolute;
    width: 200px;
    top: 52%;
    right: 30px;
    
}
.projects-container .projects .project-container .speech-bubble.medium3 img{
    width: 250px;
    position: absolute;
    top: 55%;
    right: 0%;
}
.projects-container .projects .project-container .speech-bubble.medium4 span{
    position: absolute;
    width: 150px;
    top: 70%;
    left: 0%;
}
.projects-container .projects .project-container .speech-bubble.medium4 img{
    width: 150px;
    position: absolute;
    top: 50%;
    left: 0%;
}
.projects-container .projects .project-container .speech-bubble.medium3 span{
    position: absolute;
    width: 150px;
    top: 70%;
    right: 10%;
    
}
.projects-container .projects .project-container .speech-bubble.large img{
    width: 300px;
    position: absolute;
    top: 35%;
    left: 10%;
}
.projects-container .projects .project-container .speech-bubble.large span{
    position: absolute;
    width: 200px;
    top: 54%;
    left: 20%;
    
}
.projects-container .projects .project-container .speech-bubble.large2 img{
    width: 250px;
    position: absolute;
    top: 10%;
    right: 20%;
}
.projects-container .projects .project-container .speech-bubble.large2 span{
    position: absolute;
    width: 150px;
    top: 40%;
    right: 25%;
    
}
.projects-container .projects .project-container .speech-bubble.large3 img{
    width: 250px;
    position: absolute;
    top: 10%;
    left: 20%;
}
.projects-container .projects .project-container .speech-bubble.large3 span{
    position: absolute;
    width: 150px;
    top: 40%;
    left: 25%;
    
}
.projects-container .projects .project-container .speech-bubble.large4 img{
    width: 250px;
    position: absolute;
    top: 10%;
    left: 20%;
}
.projects-container .projects .project-container .speech-bubble.large4 span{
    position: absolute;
    width: 150px;
    top: 40%;
    left: 30%;
    
}
@media(max-width: 1400px){
    .projects-container{
        padding: 75px 150px;
    }
    .projects-container .header{
        font-size: 40px;
    }
    .projects-container .projects .project-container .project3{
        background-position: 50% 50%;
    }
    .projects-container .projects .project-container .speech-bubble.small span{
        left: 10%;
    }
    .projects-container .projects .project-container .speech-bubble.medium img{
        width: 150px;
        position: absolute;
        left: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.medium span{
        position: absolute;
        top: 20%;
        left: 8%;
        font-size: 15px;
        
    }
    .projects-container .projects .project-container .speech-bubble.medium2 img{
        width: 225px;
        position: absolute;
        top: 20%;
        right: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.medium2 span{
        position: absolute;
        width: 225px;
        top: 50%;
        right: 0%;
        font-size: 15px;
    }
    .projects-container .projects .project-container .speech-bubble.medium3 img{
        width: 225px;
        position: absolute;
        top: 65%;
        right: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.medium3 span{
        position: absolute;
        width: 150px;
        top: 78%;
        right: 12%;
        
    }
    .projects-container .projects .project-container .speech-bubble.large img{
        width: 200px;
        position: absolute;
        top: 35%;
        left: 10%;
    }
    .projects-container .projects .project-container .speech-bubble.large span{
        position: absolute;
        width: 125px;
        top: 46%;
        left: 21%;
        font-size: 15px;
    }
    .projects-container .projects .project-container .speech-bubble.large2 img{
        width: 200px;
        position: absolute;
        top: 10%;
        right: 20%;
    }
    .projects-container .projects .project-container .speech-bubble.large2 span{
        position: absolute;
        width: 150px;
        top: 35%;
        right: 24%;
        font-size: 15px;
    }
    .projects-container .projects .project-container .speech-bubble.large3 img{
        width: 250px;
        position: absolute;
        top: 10%;
        left: 20%;
    }
    .projects-container .projects .project-container .speech-bubble.large3 span{
        position: absolute;
        width: 150px;
        top: 40%;
        left: 27%;
        
    }
    .projects-container .projects .project-container .speech-bubble.large4 img{
        width: 150px;
        position: absolute;
        top: 10%;
        left: 20%;
    }
    .projects-container .projects .project-container .speech-bubble.large4 span{
        position: absolute;
        width: 100px;
        top: 25%;
        left: 27.5%;
        
    }

}
@media(max-width: 1200px){
    .projects-container .header{
        font-size: 30px;
    }

}
@media(max-width: 1000px){
    .projects-container{
        padding: 100px;
    }
    .projects-container .header{
        margin: 0 0 50px 0;
    }

}
@media(max-width: 850px){
    .projects-container{
        padding: 100px 50px;
    }
}
@media(max-width: 600px){
    .projects-container{
        padding: 100px 25px 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        margin-bottom: -100px;
        
      }
      .projects-container .projects{
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(5, 150px);
    }
    
    .projects-container .header{
        font-size: 25px;
    }
    .projects-container .projects .project-container{
        border-width: 3px;
    }
    .projects-container .projects .project-container .project1, .projects .project-container .project2, .projects .project-container .project4{
        width: 90%;
        display: block;
        margin: auto;
        height: 90%;
        position: relative;
    }
    .projects-container .projects .project-container .project1{
        border: 1.5px solid #0C0F11;
    }
    .projects-container .projects .project-container .project2{
        border: 1.5px solid #0C0F11;
    }
    .projects-container .projects .project-container .project3{
        border: 1.5px solid #0C0F11;
        height: 95%;
        width: 90%;
        margin: auto;
        position: relative;
    }
    .projects-container .projects .project-container .project4{
        border: 1.5px solid #0C0F11;
        width: 95%;
        height: 90%;
    }
    .projects-container .projects .project-container .project5{
        border: 1.5px solid #0C0F11;
        width: 90%;
        height: 95%;
    }
    .projects-container .projects .project-container .project6{
        border: 1.5px solid #0C0F11;
        width: 90%;
        height: 90%;
    }
    .projects-container .projects .project-container .project7{
        border: 1.5px solid #0C0F11;
        width: 90%;
        height: 90%;
    }
    .projects-container .projects .project-container .project8{
        border: 1.5px solid #0C0F11;
        width: 95%;
        height: 90%;
    }
    .projects-container .projects .project-container .project9{
        border: 1.5px solid #0C0F11;
        width: 90%;
        height: 90%;
    }
    .projects-container .projects .project-container .project10{
        border: 1.5px solid #0C0F11;
        width: 95%;
        height: 90%;
    }
    .projects-container .projects .project-container .speech-bubble span{
        font-size: 7px!important;
    }
    .projects-container .projects .project-container .speech-bubble.small img{
        width: 60px;
        position: absolute;
        left: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.small span{
        position: absolute;
        top: 15%;
        left: 15%;
    }
    .projects-container .projects .project-container .speech-bubble.medium img{
        width: 80px;
        position: absolute;
        left: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.medium span{
        position: absolute;
        top: 22%;
        left: 15%;
    }
    .projects-container .projects .project-container .speech-bubble.medium2 img{
        width: 110px;
        position: absolute;
        top: 10%;
        right: -5%;
    }
    .projects-container .projects .project-container.two .speech-bubble.medium2 img{
        width: 75px;
        position: absolute;
        top: 10%;
        right: -5%;
    }
    .projects-container .projects .project-container.nine .speech-bubble.medium2 img{
        width: 100px;
        position: absolute;
        top: 35%;
        right: -5%;
    }
    .projects-container .projects .project-container.two .speech-bubble.medium2 span{
        position: absolute;
        width: 65px;
        top: 31%;
        right: 1%;
    }
    .projects-container .projects .project-container.nine .speech-bubble.medium2 span{
        position: absolute;
        width: 65px;
        top: 65%;
        right: 10%;
    }
    .projects-container .projects .project-container .speech-bubble.medium2 span{
        position: absolute;
        width: 85px;
        top: 40%;
        right: 1%;
    }
    .projects-container .projects .project-container .speech-bubble.medium3 img{
        width: 100px;
        position: absolute;
        top: 65%;
        right: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.medium3 span{
        position: absolute;
        width: 70px;
        top: 77%;
        right: 17%;
        
    }
    .projects-container .projects .project-container .speech-bubble.medium4 span{
        position: absolute;
        width: 150px;
        top: 70%;
        left: -37%;
    }
    .projects-container .projects .project-container .speech-bubble.medium4 img{
        width: 75px;
        position: absolute;
        top: 50%;
        left: 0%;
    }
    .projects-container .projects .project-container .speech-bubble.large img{
        width: 90px;
        position: absolute;
        top: 35%;
        left: 10%;
    }
    .projects-container .projects .project-container .speech-bubble.large span{
        position: absolute;
        width: 50px;
        top: 44%;
        left: 27%;
    }
    .projects-container .projects .project-container .speech-bubble.large2 img{
        width: 140px;
        position: absolute;
        top: 5%;
        right: 2.5%;
    }
    .projects-container .projects .project-container .speech-bubble.large2 span{
        position: absolute;
        width: 85px;
        top: 43%;
        right: 16%;
    }
    .projects-container .projects .project-container .speech-bubble.large3 img{
        width: 100px;
        position: absolute;
        top: 10%;
        left: 20%;
    }
    .projects-container .projects .project-container .speech-bubble.large3 span{
        position: absolute;
        width: 70px;
        top: 32.5%;
        left: 27.5%;
        
    }
    .projects-container .projects .project-container .speech-bubble.large4 img{
        width: 90px;
        position: absolute;
        top: 10%;
        left: 10%;
    }
    .projects-container .projects .project-container .speech-bubble.large4 span{
        position: absolute;
        width: 50px;
        top: 30%;
        left: 27.5%;
        
    }
}
@keyframes borderAni{
    0% {
        transform: translate(0px, 0px);
    }
    100% {
        transform: translate(20px, 20px);
    }
}