.home-container{
    padding: 150px 150px 125px;
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(90deg, #0C0F11 60%, #FBFBFB 40%);
    margin-bottom: -90px;
    overflow: hidden;
}
.home-container .column1{
    flex: 60%;
    animation: 1s slideLeft ease;
}
.home-container .column1 .heading{
    text-align: left;
    color: #FBFBFB;
    padding: 15px 0 150px;
}
.home-container .column1 .heading .name{
    font-size: 30px;
    margin: 0 0 5px;
}
.home-container .column1 .heading .job{
    font-size: 46px;
    margin: 0 0 5px;
    background: linear-gradient(124deg, #da1d00, #d31b1b, #cfa51a, #c2c519, #18c735, #19bdc5, #2519c9, #c300d4, #dd00f3);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 1800% 1800%;
    animation: rainbow 14s ease infinite;
}
.home-container .column1 .heading .job-description{
    font-size: 20px;
    margin: 0;
}
.home-container .column1 .buttons{
    text-align: left;
    
}
@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
.home-container .column1 .buttons .button-container .button{
    display: block;
    text-align: left;
    color: #0C0F11;
    background-color: #FBFBFB;
    background: #FBFBFB;
    padding: 20px 10px;
    width: 30%;
    margin: 30px 0;
    transition: all 0.5s ease;
    
}
.home-container .column1 .buttons .button-container .button:hover{
    width: 40%;
    cursor: pointer;
    background: linear-gradient(124deg, #da1d00, #d31b1b, #cfa51a, #c2c519, #18c735, #19bdc5, #2519c9, #c300d4, #dd00f3);
    color: #FBFBFB;
    background-size: 1800% 1800%;
    animation: rainbow 18s ease infinite;
    transition: all 0.5s ease;
}
.home-container .column1 .buttons .button-container .button.two:hover{
    background: linear-gradient(124deg, #2519c9, #c300d4, #dd00f3, #cfa51a, #c2c519, #18c735, #da1d00, #d31b1b,#19bdc5  );
    background-size: 1800% 1800%;
    animation: rainbow 18s ease infinite;
    transition: all 0.5s ease;
}
@keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
.home-container .column1 .socials{
    display: flex;
    align-items: center;
}
.home-container .column1 .socials a{
    padding: 0 7.5px;
}
.home-container .column1 .socials .ig{
    padding: 0 7.5px 0 0;
}
.home-container .column1 .socials a img{
    width: 25px;
}
.home-container .column1 .socials .twitch img{
    width: 20px
}
.home-container .column1 .socials a:hover{
    animation: rainbowIcon 60s ease infinite;
    transform: scale(1.1);
    transition: all .5s ease;
}
@keyframes rainbowIcon{
    0%{
        filter: invert(17%) sepia(100%) saturate(3281%) hue-rotate(6deg) brightness(90%) contrast(107%);
    }
    12.5%{
        filter: invert(24%) sepia(51%) saturate(4466%) hue-rotate(349deg) brightness(83%) contrast(100%);
    }
    25%{
        filter: invert(55%) sepia(75%) saturate(457%) hue-rotate(9deg) brightness(102%) contrast(96%);
    }
    37.5%{
        filter: invert(56%) sepia(100%) saturate(344%) hue-rotate(22deg) brightness(106%) contrast(98%);
    }
    50%{
        filter: invert(54%) sepia(97%) saturate(583%) hue-rotate(81deg) brightness(92%) contrast(92%);
    }
    62.5%{
        filter: invert(51%) sepia(92%) saturate(387%) hue-rotate(134deg) brightness(99%) contrast(95%);
    }
    75%{
        filter: invert(15%) sepia(61%) saturate(5772%) hue-rotate(239deg) brightness(83%) contrast(102%);
    }
    87.5%{
        filter: invert(19%) sepia(100%) saturate(4294%) hue-rotate(288deg) brightness(87%) contrast(128%);
    }
    100%{
        filter: invert(22%) sepia(100%) saturate(4493%) hue-rotate(287deg) brightness(96%) contrast(130%);
    }
}
.home-container .column1 .socials .github, .socials .linkedin{
    padding: 0 2.5px;
}
.home-container .column2{
    flex: 40%;
    position: relative;
    overflow: visible;
    animation: 1s slideRight ease;
}
.home-container .column2 img{
    width: 600px;
    position: absolute;
    right: 1%;
}
@media(max-width: 1400px){
    .home-container{
        padding: 180px 150px 125px;
    }
    .home-container .column1 .heading{
        padding: 15px 0 25px;
    }
    .home-container .column2 img{
        width: 500px;
        position: absolute;
        right: -10%;
    }
    .home-container .column1 .heading .name{
        font-size: 25px;
        margin: 0 0 5px;
    }
    .home-container .column1 .heading .job{
        font-size: 40px;
        margin: 0 0 5px;
    }
    .home-container .column1 .heading .job-description{
        font-size: 15px;
        margin: 0;
    }
    .home-container .column1 .buttons .button-container .button{
        width: 35%;
    }
}
@media(max-width: 1200px){
    .home-container .column1 .heading{
        margin-right: 75px;
    }
    .home-container .column1 .heading .name{
        font-size: 20px;
    }
    .home-container .column1 .heading .job{
        font-size: 30px;
    }
    .home-container .column1 .heading .job-description{
        font-size: 12px;
    }
    .home-container .column1 .buttons{
        font-size: 12px;
    }
    .home-container .column1 .socials a img{
        width: 25px;
    }
}
@media(max-width: 1000px){
    .home-container{
        padding: 100px 100px;
    }
}
@media(max-width: 850px){
    .home-container{
        padding: 100px 50px 100px 50px;
        background: linear-gradient(140deg, #0C0F11 60%, #FBFBFB 40%);
    }
    .home-container .column1{
        flex: 100%;
    }
    .home-container .column1 .heading{
        margin-right: 30px;
    }
    .home-container .column1 .buttons .button-container .button{
        margin: 15px 0;
        padding: 15px 10px;
        width: 35%;
    }
    .home-container .column1 .buttons .button-container .button:hover{
        width: 50%;
    }
    .home-container .column1 .socials a img{
        width: 20px;
    }
    .home-container .column2{
        flex: 100%;
        position: absolute;
        overflow: visible;
        right: 7%;
        top: 7.5%;
    }
    .home-container .column2 img{
        width: 300px;
        position: unset;
        right: 0%;
    }
}
@media(max-width: 600px){
    .home-container{
        padding: 85px 25px 75px 25px;
        margin-bottom: -60px;
    }
    .home-container .column1 .heading .name{
        font-size: 15px;
    }
    .home-container .column1 .heading .job{
        font-size: 25px;
    }
    .home-container .column1 .heading .job-description{
        font-size: 10px;
    }
    .home-container .column1 .buttons{
        font-size: 10px;
    }
    
    .home-container .column2{
        flex: 100%;
        position: absolute;
        overflow: visible;
        right: 7%;
        top: 35%;
    }
    .home-container .column2 img{
        width: 175px;
        position: unset;
        right: 0%;
    }
    .home-container .column1 .socials a{
        padding: 0 5px;
    }
    .home-container .column1 .socials .ig{
        padding: 0 5px 0 0;
    }
    .home-container .column1 .socials a img{
        width: 17.5px;
    }
    .home-container .column1 .socials .twitch img{
        width: 15px;
    }
}
@keyframes slideLeft{
    0%{
        opacity: 0;
        transform: translateX(-50%)
    }
    100%{
        opacity: 1;
        transform: translateX(0%)
    }
}
@keyframes slideRight{
    0%{
        opacity: 0;
        transform: translateX(50%)
    }
    100%{
        opacity: 1;
        transform: translateX(0%)
    }
}